import "../css/app.css";
import Alpine from "alpinejs";
import { Splide } from "@splidejs/splide";
// import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import GLightbox from "glightbox";
//import "./cookie3.js"

Alpine.start();

document.addEventListener("DOMContentLoaded", function () {
  const teamSection = document.querySelector(".component--team");
  const banner = document.querySelector(".slide-team");

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (banner) {
          if (entry.isIntersecting) {
            banner.classList.add("slide-show");
          } else {
            banner.classList.remove("slide-show");
          }
        }
      });
    },
    { threshold: [0.5, 0] } // 50% sichtbar → einblenden | 0% sichtbar → ausblenden
  );

  if (teamSection) observer.observe(teamSection);

  const elmsGallery = document.getElementsByClassName("splide-gallery");
  for (let i = 0, len = elmsGallery.length; i < len; i++) {
    new Splide(elmsGallery[i], {
      type: "loop",
      lazyLoad: "nearby",
      perPage: 3,
      perMove: 1,
      gap: 40,
      pagination: false,
      autoplay: true,
      speed: 2000,
      interval: 5000,
      arrowPath: "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
      breakpoints: {
        1024: {
          perPage: 2,
          gap: 20,
          arrows: true,
          pagination: false,
        },
        640: {
          perPage: 2,
          gap: 4,
          arrows: true,
          pagination: false,
        },
      },
    }).mount();
  }

  const elmsTeam = document.getElementsByClassName("splide-team");
  for (let i = 0, len = elmsTeam.length; i < len; i++) {
    new Splide(elmsTeam[i], {
      type: "loop",
      lazyLoad: "nearby",
      perPage: 3,
      perMove: 1,
      gap: 25,
      pagination: false,
      autoplay: true,
      speed: 2000,
      interval: 5000,
      arrowPath: "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z",
      breakpoints: {
        1280: {
          perPage: 3,
          gap: 2,
          arrows: true,
          pagination: false,
        },
        1024: {
          perPage: 2,
          gap: 4,
          arrows: true,
          pagination: false,
        },
        640: {
          perPage: 1,
          gap: 0,
          arrows: true,
          pagination: false,
        },
      },
    }).mount();
  }

  if (document.querySelector(".glightbox")) {
    GLightbox({
      selector: ".glightbox",
      openEffect: "fade",
      svg: {
        next: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 393.95 270.91">
                 <path fill="#fff" d="M122.6 192.18c29.92 0 54.18-24.24 54.18-54.13s-24.26-54.13-54.18-54.13-54.18 24.24-54.18 54.13 24.26 54.13 54.18 54.13"/>
                 <path fill="#fff" d="M196.98 270.91c-29.92 0-54.18-24.24-54.18-54.13s24.26-54.13 54.18-54.13 54.18 24.24 54.18 54.13-24.26 54.13-54.18 54.13"/>
                 <path fill="#fff" d="M54.18 108.26C24.26 108.26 0 84.03 0 54.13S24.26 0 54.18 0s54.18 24.23 54.18 54.13-24.26 54.13-54.18 54.13"/>
                 <path fill="#fff" d="M271.35 192.18c-29.92 0-54.18-24.24-54.18-54.13s24.26-54.13 54.18-54.13 54.18 24.24 54.18 54.13-24.26 54.13-54.18 54.13"/>
                 <path fill="#fff" d="M339.78 108.26c29.92 0 54.18-24.24 54.18-54.13S369.7 0 339.78 0s-54.18 24.23-54.18 54.13 24.26 54.13 54.18 54.13"/>
               </svg>`,
        prev: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 393.95 270.91">
                 <path fill="#fff" d="M122.6 192.18c29.92 0 54.18-24.24 54.18-54.13s-24.26-54.13-54.18-54.13-54.18 24.24-54.18 54.13 24.26 54.13 54.18 54.13"/>
                 <path fill="#fff" d="M196.98 270.91c-29.92 0-54.18-24.24-54.18-54.13s24.26-54.13 54.18-54.13 54.18 24.24 54.18 54.13-24.26 54.13-54.18 54.13"/>
                 <path fill="#fff" d="M54.18 108.26C24.26 108.26 0 84.03 0 54.13S24.26 0 54.18 0s54.18 24.23 54.18 54.13-24.26 54.13-54.18 54.13"/>
                 <path fill="#fff" d="M271.35 192.18c-29.92 0-54.18-24.24-54.18-54.13s24.26-54.13 54.18-54.13 54.18 24.24 54.18 54.13-24.26 54.13-54.18 54.13"/>
                 <path fill="#fff" d="M339.78 108.26c29.92 0 54.18-24.24 54.18-54.13S369.7 0 339.78 0s-54.18 24.23-54.18 54.13 24.26 54.13 54.18 54.13"/>
               </svg>`,
      },
    });
  }
});
